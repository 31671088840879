.root {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.zoomContainer {
    border-radius: 44px;
    box-shadow: var(--shadows-bottomNormal) !important;
}

.zoomContainer > div > button {
    opacity: 1 !important;
    box-shadow: none !important;
}

.zoomContainer > div > button:first-child {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.zoomContainer > div > button:last-child {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    margin-block-start: 0 !important;
    transform: translateY(1px);
}

[dir='rtl'] .root {
    right: auto;
    left: 5px;
}

.geoControl {
    position: absolute;
    right: 50%;
    top: calc(100% + var(--spacingUnits-s));
    transform: translateX(50%);
    box-shadow: var(--shadows-bottomNormal) !important;
}

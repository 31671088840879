.amber-tumbler {
    position: relative;
    vertical-align: middle;
    min-width: 0;

    &__control {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        margin: 0;
        padding: 0;
        opacity: 0;
        cursor: pointer;
    }

    &__label {
        min-width: 0;
        padding-left: 40px;

        &:after, &:before {
            margin-top: -12px;
        }
    }
}

.amber-tumbler.amber-tumbler_round {
    display: flex;
    width: 42px;
    justify-content: flex-end;

    .amber-tumbler__content {
        position: relative;
        min-width: 24px;
        transition: min-width 0.3s;
        will-change: min-width;

        &:before {
            position: absolute;
            right: 0;
            top: 0;
            min-width: 0;
        }

        &:after {
            position: absolute;
            top: 50%;
            margin-top: -10px;
            width: 20px;
            height: 20px;
            left: 2px;
        }
    }

    &.amber-tumbler_checked .amber-tumbler__content {
        &:after {
            left: auto;
            right: 2px;
        }
    }

    &:hover {
        .amber-tumbler__content {
            min-width: 42px;
        }
    }
}

.TableRow:hover .amber-tumbler.amber-tumbler_round .amber-tumbler__content {
    min-width: 42px;
}

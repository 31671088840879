.sticker {
    height: 24px;
    padding: 0 7px;
    background-color: var(--colorScheme-background);
    filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.1));
    white-space: nowrap;
    visibility: hidden;
}

.content,
.sticker::before,
.sticker::after {
    height: 100%;
    background: inherit;
    visibility: visible;
}

.content {
    z-index: 1;
}

.sticker::before,
.sticker::after {
    content: '';
    position: absolute;
    top: 0;
    width: 8px;
    mask-position: center;
}

.sticker::before {
    left: 0;
    mask-image: url("data:image/svg+xml,%3Csvg width='9' height='25' viewBox='0 0 9 25' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.584.658H5.521a.937.937 0 0 0-.937.937c0 2.893-2.036 5.407-3.3 8.01a6.972 6.972 0 0 0-.7 3.053c0 1.095.252 2.131.7 3.054 1.264 2.603 3.3 5.116 3.3 8.01 0 .517.42.936.937.936h3.063v-24Z'/%3E%3C/svg%3E");
}

.sticker::after {
    right: 0;
    mask-image: url("data:image/svg+xml,%3Csvg width='9' height='25' viewBox='0 0 9 25' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.584.658h3.063c.518 0 .937.42.937.937 0 2.893 2.037 5.407 3.3 8.01.449.922.7 1.958.7 3.053a6.971 6.971 0 0 1-.7 3.054c-1.263 2.603-3.3 5.116-3.3 8.01 0 .517-.42.936-.937.936H.584v-24Z'/%3E%3C/svg%3E");
}

.header {
    height: 85px;
    max-width: 100%;
}

.title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
}

.toggleButton {
    color: inherit;
    background-color: transparent !important;
    border-radius: var(--border-radius) !important;
    text-align: left;
    max-width: 100%;
}

.toggleIcon {
    margin-inline-start: -4px;
    transition: transform var(--collapsing-duration) ease-in-out;
}

.opened .toggleIcon {
    transform: rotate(180deg);
}

.contentWrapper {
    overflow: hidden;
}

.controls {
    max-width: 100%;
}

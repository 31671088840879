.SuggestOption {
    padding: ($unit * 2) $unit;
    border-bottom: 1px solid $WarmGray100;
    line-height: 24px;
    cursor: pointer;

    &:hover, &_selected {
        background: $WarmGray100;
    }

    &__description {
        opacity: 0.7;
    }
}

@component amber-button {
    @when theme_fill {
        @when disabled {
            opacity: 0.5;
            cursor: default;
        }
    }
    @when theme_transparent {
        border: none;
        background: transparent;
        cursor: pointer;
    }

    --button-xxl-height: calc(env(--unit) * 9);

    @when bottom {
        width: 100%;
        border-radius: 0 0 env(--unit) env(--unit) !important;
        height: var(--button-xxl-height);
    }
    @when bottom-right {
        border-radius: 0 0 env(--unit) 0 !important;
        width: 100%;
        height: var(--button-xxl-height);
    }
    @when bottom-left {
        border-radius: 0 0 0 env(--unit) !important;
        width: 100%;
        height: var(--button-xxl-height);
    }
}

.FloatingButton {
    & .amber-button__icon {
        width: auto !important;
        height: auto !important;
    }
}

.modal {
    margin: var(--spacingUnits-l);
    padding: var(--spacingUnits-l);
    outline: none;
    position: relative;
    text-align: left;
}

.modal,
.modal * {
    box-sizing: border-box;
}

.flexModal {
    max-height: calc(100% - var(--spacingUnits-l) * 2);
    overflow-y: auto;
}

.backdrop {
    z-index: 0;
}

.closeIcon {
    flex-shrink: 0;
    cursor: pointer;
    color: var(--colors-warmGray-250);
}

.relativeCloseIcon {
    margin-inline-start: var(--spacingUnits-m);
}

.absoluteCloseIcon {
    position: absolute;
    inset-block-start: var(--spacingUnits-l);
    inset-inline-end: var(--spacingUnits-l);
}

.disabledCloseIcon {
    cursor: default;
}

.title {
    margin: 0;
    align-items: flex-start;
    flex: 0 0 auto;
}

.body {
    flex: 1 1 auto;
    box-sizing: content-box;
}

.footer {
    flex: 0 0 auto;
    margin-top: 40px;
}

.content {
    flex: 0 0 auto;
}

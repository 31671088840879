.price {
    white-space: nowrap;
    font-weight: 400;

    .currency-sign {
        opacity: 0.7;
        padding-left: 3px;
    }

    &_theme_bold {
        font-weight: 600;
    }

    &_theme_no-opacity {
        .currency-sign {
            opacity: 1;
        }
    }

    &_theme_inherit {
        font-weight: inherit;

        .currency-sign {
            opacity: unset;
        }
    }
}

.amber-alert a {
    color: white;
}

.amber-alert_type_warning {
    background-color: env(--AmberToxicColor);
}

.WarningsAlert {
    background-color: env(--AmberToxicColor);
}

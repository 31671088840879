.row.minor {
    background-color: var(--colorScheme-bgMinor);
    margin-block-end: 1px;
}

.row.minor:first-child {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.row.minor:last-child {
    margin: 0;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

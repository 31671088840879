.container {
    position: relative;
    overflow: hidden;
    background-color: var(--colorScheme-bgMinor);
}

.gradient {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--components-buttons-loadingGradient);
}

.rounded,
.text {
    border-radius: 8px;
}

.text {
    height: 1em;
}

.circular {
    border-radius: 50%;
}

.panel {
    width: 360px;

    --titleHeight: 0;
    --footerHeight: 0;
}

.panel.mobileSheet {
    overflow: visible;
}

.panel.mobileSheet::before {
    content: '';
    display: block;
    position: absolute;
    width: 40px;
    height: 3px;
    border-radius: 12px;
    inset-block-start: -12px;
    inset-inline-start: 50%;
    transform: translateX(-50%);
    background: var(--colorScheme-background);
}

.stick {
    display: block;
    position: absolute;
    width: 40px;
    height: 3px;
    border-radius: 12px;
    inset-block-start: -12px;
    inset-inline-start: 50%;
    transform: translateX(-50%);
    background: var(--colorScheme-background);
}

.title {
    padding: 16px var(--spacingUnits-m);
}

.titleIsland {
    background-color: var(--colorScheme-background);
    border-radius: 24px;
    box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.12);
    z-index: 2;
}

.closeButton {
    flex-shrink: 0;
    margin-left: var(--spacingUnits-m);
}

.body {
    z-index: 1;
    overflow: auto;
}

.transparent {
    background: transparent;
}

.titlePadding {
    padding-block-start: calc(1px * var(--titleHeight));
}

.titleMargin {
    margin-block-start: calc(-1px * var(--titleHeight));
}

.footerPadding {
    padding-block-end: calc(1px * var(--footerHeight));
}

.footerMargin {
    margin-block-end: calc(-1px * var(--footerHeight));
}

.section:last-child {
    padding-bottom: var(--spacingUnits-xl);
}

.footer {
    --border-radius: unset !important;

    margin-top: auto;
    box-shadow: var(--shadows-topNormal);
    z-index: 1;
}

.left .footer {
    border-bottom-left-radius: 0;
}

.right .footer {
    border-bottom-right-radius: 0;
}

.bottom .footer {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.islandGroup {
    background-color: #c4c4c4;
    overflow: unset;
    box-shadow: unset;
    min-height: fit-content;
}

.island {
    padding: var(--spacingUnits-m);
    overflow: unset;
    min-height: fit-content;
}

.py.py {
    padding-block-start: var(--py);
    padding-block-end: var(--py);
}

.pt.pt {
    padding-block-start: var(--pt);
}

.pb.pb {
    padding-block-end: var(--pb);
}

.px.px {
    padding-inline-start: var(--px);
    padding-inline-end: var(--px);
}

.pl.pl {
    padding-inline-start: var(--pl);
}

.pr.pr {
    padding-inline-end: var(--pr);
}

.island.noOverflow {
    overflow: hidden;
}

.island.noPadding {
    padding: 0;
}

.island.noPaddingTop {
    padding-block-start: 0;
}

.island.noPaddingBottom {
    padding-block-end: 0;
}

.island.noShadow {
    box-shadow: unset;
}

.island.noFirst,
.island:not(:first-child) {
    margin-block-start: 2px;
}

.island:not(.noFirst):first-child {
    border-top-right-radius: 0;
}

.island:not(.noLast):last-child {
    flex-grow: 1;
    border-bottom-right-radius: 0;
}

.island.s:first-child {
    border-top-right-radius: inherit;
}

.island.s:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

* {
    outline: none;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-family: $FF;
    font-size: 10px;
}

body {
    font-family: $FF;
    font-size: 13px;
    line-height: 1.2;
    font-weight: normal;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: subpixel-antialiased;
    color: #21201F;
}

table {
    border-collapse: collapse;
    width: 100%;
}

h1, h2 {
    font-family: $FF;
    font-size: 32px;
    font-weight: 400;
    word-spacing: 4px;
    padding: 0;
    margin: 0;
}

h3 {
    font-family: $FF;
    font-size: 32px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.island {
    background: #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 3px 4px -3px rgba(0, 0, 0, 0.3);
}

hr {
    box-sizing: content-box;
    height: 0;
    border: 0;
    padding: 0;
    border-top: 1px solid #eeeeee;
    flex-grow: 1;
    margin-top: 0;
    margin-bottom: 0;
}

ul {
    margin 0;
    padding: 0;
}

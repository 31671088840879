.cardSpoiler {
    padding-block-end: 24px;
    margin-block-end: -24px;
    z-index: 1;
}

.cardSpoiler.large {
    border-radius: 24px 24px 0 0;
}

.cardSpoiler.medium {
    border-radius: 14px 14px 0 0;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px;
}

.cardSpoiler.error {
    color: var(--colors-absolute-white);
    background-color: var(--colors-red-normal);
}

.cardSpoiler.success {
    color: var(--colors-absolute-white);
    background-color: var(--colors-green-normal);
}

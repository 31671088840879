.HorizontalFieldLayout {
    margin-bottom: $unit;

    &__label {
        display: flex;
        min-height: $unit * 5;
    }

    &__help {
        margin-inline-start: 2px;
        margin-block-start: -2px;
    }
}

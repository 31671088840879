.tooltip.tooltip.tooltip {
    background-color: var(--colorScheme-bgEducation);
    color: var(--colors-warmGray-200);
    text-align: start;
}

.closeButton {
    margin: -7px;
    color: var(--colors-absolute-white);
}

.target {
    position: absolute;
    width: 1px;
    height: 1px;
}

.buttonRound {
    background: rgba(245, 244, 242, 0.1);
    border-radius: 100px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.SuggestInput {
    position: relative;

    &_open {
        z-index: 2;
    }

    &__list {
        position: absolute;
        top: 100%;
        width: 100%;
        margin-top: $unit;
        background: white;
        max-height: 300px;
        overflow-y: auto;
        box-shadow: 0 8px 32px 0 #DCDEE0;
        border-radius: ($unit / 2);
    }

    &__clear {
        cursor: pointer;
    }

    &__spinner, &__clear, .spinner {
        transform: scale(0.7);
    }

    &__placeholder_iconRight {
        right: 46px;
    }

    .amber-input__icon_right .amber-icon {
        transition: opacity 300ms;
        opacity: 0.7;

        &:hover {
            opacity: 1;
        }
    }

    &__placeholder {
        background: white;
        top: 3px;
        left: 2px;
        padding-left: 7px;
        right: 40px;
        width: auto;
        position: absolute;
        line-height: 34px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &_iconLeft {
            padding: 0 7px;
            left: 34px;
        }
    }

    .amber-input_theme_underline + .SuggestInput__placeholder {
        padding-left: 0;
        left: 0;

        &_iconLeft {
            padding: 0 7px;
            left: 34px;
        }
    }
}

@import './shared.styl';

.amber-row {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    flex-wrap: nowrap;
    list-style: none;
    position: relative;
    max-width: 100%;
}

make-rows($breakpoints);

@import './shared.styl';

.amber-col {
    box-sizing: border-box;
    flex: 0 0 auto;
    max-width: 100%;
    position: relative;
}

make-columns($breakpoints, $columns);

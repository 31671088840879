.Map {
    width: 100%;
    height: 100%;
    position: relative;

    &__container {
        width: 100%;
        height: 100%;
    }
}

@import './size.styl';
@import './variables.styl';

make-row($breakpoint) {
    .amber-row {
        +variant('reverse-' + $breakpoint) {
            flex-direction: row-reverse;
        }

        +variant('verticalAlign-' + $breakpoint + '_top') {
            align-items: flex-start;
        }

        +variant('verticalAlign-' + $breakpoint + '_center') {
            align-items: center;
        }

        +variant('verticalAlign-' + $breakpoint + '_bottom') {
            align-items: flex-end;
        }

        +variant('horizontalAlign-' + $breakpoint + '_start') {
            justify-content: flex-start;
        }

        +variant('horizontalAlign-' + $breakpoint + '_center') {
            justify-content: center;
        }

        +variant('horizontalAlign-' + $breakpoint + '_end') {
            justify-content: flex-end;
        }
    }
}

make-rows($breakpoints) {
    for $breakpoint, $width in $breakpoints {
        if ($width == 0) {
            make-row($breakpoint);

            .amber-row {
                make-paddings($breakpoint);
                make-grow($breakpoint);
                make-shrink($breakpoint);
            }
        } else {
            @media (min-width: $width) {
                make-row($breakpoint);

                .amber-row {
                    make-paddings($breakpoint);
                    make-grow($breakpoint);
                    make-shrink($breakpoint);
                }
            }
        }
    }
}

.input {
    width: 100%;
}

.input input {
    padding: 0;
    border: none;
    background: transparent;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.input input:placeholder-shown {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.listItem::before {
    border: none !important;
}

.selector:not(.s) {
    max-width: 220px;
}

.popper.fixedWidth {
    min-width: 352px;
}

.popper.s.fixedWidth {
    min-width: 300px;
}

.popper.popper {
    border-radius: 16px;
}

.popper.popper li:first-of-type::before {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.popper.popper li:last-of-type::before {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

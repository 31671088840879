.dotContainer {
    border-top-right-radius: 0 !important;
}

.dot {
    position: absolute;
    border-radius: 100%;
    background: var(--colors-absolute-white);
    color: var(--colors-red-normal);
    top: -4px;
    right: -4px;
    width: 19px;
    height: 19px;
}

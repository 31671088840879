.root {
    overflow: hidden;
    transition: background-color 0.3s;
    cursor: pointer;
}

.root.checked {
    background-color: var(--colorScheme-control);
}

.root.error {
    background-color: var(--colorScheme-error);
}

.disabled {
    pointer-events: none;
    opacity: 0.7;
}

.hiddenInput.hiddenInput {
    position: absolute;
    z-index: 1;

    /** 2px - border-width on focus */
    top: 0;
    left: 0;
    width: calc(100% - 0px);
    height: calc(100% - 0px);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font: inherit;
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    appearance: none;
    border-radius: inherit;
}

.hiddenInput.hiddenInput::before {
    border-radius: inherit;
}

:global(.utilityfocus) .hiddenInput.hiddenInput:not(:hover) {
    top: 2px;
    left: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
}

.hiddenInput svg {
    display: none;
}

.sliderWrapper {
    height: 24px;
    margin-block-start: 6px;
    margin-block-end: 6px;
    padding-inline-start: 12px;
    padding-inline-end: 12px;
    user-select: none;
    position: relative;
    cursor: pointer;
}

.sliderIntermediateWrapper {
    position: absolute;
    inset-inline-start: 12px;
    inset-inline-end: 12px;
    inset-block-start: 10px;
    inset-block-end: 10px;
    z-index: 0;
    background-color: var(--colorScheme-dividerMinor);
}

.slider {
    position: absolute;
    inset: 0;
    background-color: var(--colors-yellow-toxic);
}

.pin {
    position: absolute;
    inset-block-start: 50%;
    border-radius: 50%;
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    background-color: var(--colors-yellow-toxic);
    cursor: pointer;
}

.pin.onTop {
    z-index: 1;
}

.pin::after {
    content: '';
    display: block;
    background: var(--colors-absolute-white);
    transition: opacity 0.2s;
    border-radius: 50%;
    width: 16px;
    height: 16px;
}

@media (hover: hover) and (pointer: fine) {
    .pin:hover::after,
    .pin:active::after {
        opacity: 0;
    }
}

.pin.left {
    transform: translate(-50%, -50%);
    inset-inline-start: 0;
}

.pin.right {
    transform: translate(50%, -50%);
    inset-inline-end: 0;
}

.input {
    inset: 0;
    position: absolute;
    pointer-events: none;
    cursor: pointer;
    appearance: none;
    opacity: 0;
    z-index: 3;
    padding: 0;
}

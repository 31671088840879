.island {
    position: relative;
}

.headerBackdrop {
    background: #21201f;
    position: absolute;
    top: 0;
    width: 100%;
    bottom: 24px;
    border-radius: 24px;
    z-index: 0;
}

.island:not(:last-child) {
    margin-block-end: var(--spacingUnits-s);
}

.island.xsMargin:not(:last-child) {
    margin-block-end: var(--spacingUnits-xs);
}

.island.withoutMargin {
    margin: 0 !important;
}

.paper {
    overflow: hidden;
    padding: calc(var(--spacingUnits-l) / 2) var(--spacingUnits-m);
    border-radius: var(--spacingUnits-l);
    display: block;
}

.paper.transparent {
    background: transparent;
}

.paper.group {
    background: var(--colors-warmGray-100);
}

.narrowPaper.paper {
    padding: var(--spacingUnits-s);
}

.noPadding.paper {
    padding: 0;
}

.xl.paper {
    padding: var(--spacingUnits-xl);
}

.l.paper {
    padding: var(--spacingUnits-l);
}

.noShadow.paper {
    box-shadow: none;
}

.topShadow.paper {
    box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.12);
}

.bgMinor.paper {
    background: var(--colorScheme-bgMinor);
}

.noBorderTop.paper {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.noBorderBottom.paper {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

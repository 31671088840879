.tag.processing {
    color: var(--colors-warmGray-300);
}

.tag.pending {
    color: var(--colors-orange-normal);
}

.tag.created {
    color: var(--colors-blue-normal);
}

.tag.completed {
    color: var(--colors-green-normal);
}

.tag.canceled {
    color: var(--colors-red-normal);
}

.tag.s {
    color: var(--colors-absolute-white);
    height: 17px;
}

.tag.s.processing {
    background-color: var(--colors-warmGray-300);
}

.tag.s.pending {
    color: var(--colors-orange-normal);
}

.tag.s.created {
    background-color: var(--colors-blue-normal);
}

.tag.s.completed {
    background-color: var(--colors-green-normal);
}

.tag.s.canceled {
    background-color: var(--colors-red-normal);
}

.tagText {
    white-space: nowrap;
}

.action {
    background-color: transparent;
    border: 1px solid var(--colorScheme-line);
}

.showOnHover {
    visibility: hidden;
}

.showOnHover:focus,
.row:hover .showOnHover,
.row:focus .showOnHover {
    visibility: visible;
}

.row:hover .tag {
    background-color: var(--colors-absolute-white);
}

$unit = 8px;

make-padding($side, $breakpoint) {
    +variant('padding' + $side + '-' + $breakpoint + '_xs') {
        padding-{$side}: ($unit / 2);
    }

    +variant('padding' + $side + '-' + $breakpoint + '_s') {
        padding-{$side}: $unit;
    }

    +variant('padding' + $side + '-' + $breakpoint + '_m') {
        padding-{$side}: $unit * 2;
    }

    +variant('padding' + $side + '-' + $breakpoint + '_l') {
        padding-{$side}: $unit * 3;
    }

    +variant('padding' + $side + '-' + $breakpoint + '_xl') {
        padding-{$side}: $unit * 5;
    }
}

make-paddings($breakpoint) {
    make-padding('Left', $breakpoint);
    make-padding('Right', $breakpoint);
    make-padding('Start', $breakpoint);
    make-padding('End', $breakpoint);
    make-padding('Top', $breakpoint);
    make-padding('Bottom', $breakpoint);
}

make-grow($breakpoint) {
    for $grow in 0 .. 10 {
        +variant('grow-' + $breakpoint + '_' + $grow) {
            flex-grow: $grow;
        }
    }
}

make-shrink($breakpoint) {
    for $shrink in 0 .. 10 {
        +variant('shrink-' + $breakpoint + '_' + $shrink) {
            flex-shrink: $shrink !important;
        }
    }
}

.root {
    --transitionDelay: 0.3s ease-out;
}

.content {
    transition: height var(--transitionDelay);
    overflow: hidden;
}

.divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.titleRow {
    width: 100%;
}

.chevron {
    transform: rotate(0deg);
    transition: transform var(--transitionDelay);
}

.chevron.open {
    transform: rotate(180deg);
}

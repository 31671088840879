.QuantityItem {
    position: relative;
    padding-right: 16px;

    &__subitem {
        display: flex;
        align-items: center;
        line-height: 28px;
    }

    &__label {
        line-height: 14px;
        padding-bottom: 6px;
    }

    &_collapsed &__label {
        line-height: 14px;
        padding-bottom: 13px;
    }

    &__icon {
        display: inline-block;
        position: absolute;
        left: 12px;
        top: 10px;
        width: 20px;
        height: 20px;
        background: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2213%22%20height%3D%228%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M11.3%201L6.5%205.7%201.7%201l-.7.7L6.5%207%2012%201.7l-.7-.7z%22%2F%3E%3C%2Fsvg%3E');
        background-position: center;
        vertical-align: bottom;
        background-repeat: no-repeat;
        transform: rotate(0deg);
        transition: transform 0.3s;
        z-index: 2;
        cursor: pointer;
    }

    &_collapsed .QuantityItem__icon {
        transform: rotate(-90deg);
    }

    &__subitem-label {
        flex-grow: 2;
        opacity: 0.6;
    }

    .dropdown__item {
        &:hover {
            background: transparent;
        }

        &_is-current:before {
            background-position-y: 6px;
        }
    }
}

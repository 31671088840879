.container {
    display: inline-block;
    line-height: 1.25;
    white-space: nowrap;
}

.container > span:not(:last-child) {
    margin-right: 2px;
}

.lowercase {
    text-transform: lowercase;
}

.sup {
    font-size: 0.7em;
    vertical-align: top;
}

.yandex {
    width: 230px;
    height: 32px;
    background: url('https://yastatic.net/s3/taxi-corp-client/static-images/logo/yandex-ru-large.svg');
}

.yandex.newYear {
    width: 223px;
    height: 32px;
    background: url('https://yastatic.net/s3/taxi-corp-client/static-images/logo/new-year/yandex-ru-large.png');
    background-size: cover;
}

.yandex.compact {
    width: 126px;
    height: 26px;
    background: url('https://yastatic.net/s3/taxi-corp-client/static-images/logo/yandex-ru-small.svg');
}

.yandex.compact.newYear {
    width: 129px;
    height: 26px;
    background: url('https://yastatic.net/s3/taxi-corp-client/static-images/logo/new-year/yandex-ru-small.png');
    background-size: cover;
}

.yandex.en {
    width: 232px;
    height: 32px;
    background: url('https://yastatic.net/s3/taxi-corp-client/static-images/logo/yandex-en-large.svg');
}

.yandex.en.newYear {
    width: 224px;
    height: 32px;
    background: url('https://yastatic.net/s3/taxi-corp-client/static-images/logo/new-year/yandex-en-large.png');
    background-size: cover;
}

.yandex.en.compact {
    width: 124px;
    height: 26px;
    background: url('https://yastatic.net/s3/taxi-corp-client/static-images/logo/yandex-en-small.svg');
}

.yandex.en.compact.newYear {
    width: 125px;
    height: 26px;
    background: url('https://yastatic.net/s3/taxi-corp-client/static-images/logo/new-year/yandex-en-small.png');
    background-size: cover;
}

.yango {
    width: 79px;
    height: 36px;
    background: url('https://yastatic.net/s3/taxi-corp-client/static-images/logo/yango.svg');
}

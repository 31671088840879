.amber-typography {
    &_body {
        font-size: 14px !important;
        line-height: 1.4;
        font-weight: normal;
        color: #444 !important;
    }

    &_labelText {
        font-size: 14px !important;
        line-height: 1.4;
        font-weight: normal;
        color: $WarmGray500 !important;
    }

    &_comment {
        font-size: 12px !important;
        line-height: 1.3;
        font-weight: normal;
        color: $WarmGray350 !important;
    }

    &_fade {
        opacity: 0.5;
    }

    &_fade2 {
        opacity: 0.7;
    }

    &_nomargin {
        margin: 0;
    }

    &__heading3 {
        font-size: 20px;
        line-height: 24px;
        color: #21201F;
        font-weight: bold;
    }
}

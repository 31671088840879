.text {
    margin: 0;
    padding: 0;

    &_type_load {
        color: #1d1d1d;
        font-size: 20px;
    }

    &_type_auth {
        color: #767676;
        text-align: center;
        font-size: 26px;
    }

    &_type_complete {
        font-size: 20px;
        line-height: 1.5em;
        margin: 0 0 40px;
    }

    &_type_message {
        font-size: 26px;
        line-height: 1.5em;
        color: #161616;
    }
}

.titleIsland {
    box-shadow: none;
    margin-block-end: 2px;
    padding-inline-start: 16px;
    padding-inline-end: 16px;
    padding-block-start: 17px;
    padding-block-end: 17px;
}

.container {
    background-color: var(--colors-absolute-black);
}

.bodyIsland {
    --cell-size: min(
        calc((100vw - 2 * var(--spacingUnits-m) - 6 * 4px) / 7),
        var(--components-slots-size-l)
    );
    --padding: calc(var(--components-slots-size-l) + 2 * var(--spacingUnits-s));

    box-shadow: none;
    padding-block-end: var(--padding);
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    width: 100%;

    /* для выравнивания календарей с разным кол-вом дней */
    height: calc(
        (var(--cell-size) + 4px) * 6 + 46px + 32px + var(--padding) + var(--spacingUnits-l)
    );
}

.action {
    position: absolute;
    bottom: 0;
    box-shadow: var(--shadows-topNormal);
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    width: 100%;
}

.calendar {
    width: 100%;
}

.bodyIsland table {
    --components-slots-size-s: var(--cell-size);

    border-collapse: unset !important;
}

nowrap() {
    min-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

wrap() {
    white-space: normal;
    overflow: visible;
}

.nowrap {
    nowrap();
}

.page-404, .page-403 {
    padding: 50px;
}

.gray {
    color: #949392;
}

.react-datepicker .react-datepicker__navigation {
    z-index: 1;
}

.react-datepicker__tether-element {
    z-index: 2147483647;
}

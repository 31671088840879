/* Для кликабельных элементов, которые нужно обернуть в кнопку для accessability */
.clearButton {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    text-align: initial;
    position: relative;
    box-sizing: border-box;
    color: inherit;
    align-items: unset;
}

:global(.utilityfocus) .clearButton:not(.noBorder):not(.pressed):focus::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: inherit;
    pointer-events: none;
    border: var(--components-buttons-default-borderFocused);
}

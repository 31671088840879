.root {
    position: relative;
    margin: 0 calc(-1 * var(--spacingUnits-l));
    overflow: hidden;
}

.content {
    width: 100%;
    height: 100%;
    padding: 0 var(--spacingUnits-l);
}

.withActions .content {
    height: calc(100% - 80px);
}

.actionsPanel {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    z-index: 10;
}

.actionsPanelContent {
    position: relative;
    width: 100%;
    height: 80px;
    background-color: var(--colorScheme-background);
    box-shadow: var(--shadows-topNormal);
}

.amber-input {
    width: 100%;

    &_disabled {
        .amber-input__icon {
            opacity: 0.5;
        }
    }

    &_theme_search {
        .amber-input__control {
            width: 100%;
            border: none;
            border-radius: 20px;
            padding-left: $unit * 3;
            padding-right: $unit * 3;

            &::placeholder {
                text-align: center;
            }
        }
    }
}

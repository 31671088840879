has($element) {
    /&__{$element} {
        {block};
    }
}

variant($modifier) {
    /&_{$modifier} {
        {block};
    }
}

.amber-select {
    &_nocheck {
        .Select-option {
            padding-left: $unit * 2;

            &.is-selected {
                background-image: none;
            }
        }
    }

    &_async_error .Select-control, &_error .Select-control {
        border-width: 2px;
        border-color: $RedToxic !important;
    }

    .Select {
        &-option {
            [dir=rtl] & {
                background-position: right 12px center !important;
            }

            &.is-selected {
                background-color: transparent;
            }
        }

        &.is-clearable .Select-value {
            padding-right: 44px;
        }

        &-noresults {
            padding-top: $unit;
            padding-bottom: $unit;
        }

        // Убрать крестик при вводе номера в IE/Edge
        &-input input::-ms-clear {
            display: none !important;
        }

        &-input input {
            line-height: 38px;
        }
    }

    &_forcedPlaceholder {
        .Select-placeholder {
            align-items: center;
            padding-left: $unit;
            user-select: none;
            outline: none;

            &_with-value {
                color: inherit;
            }
        }

        .amber-icon:first-child {
            margin-right: ($unit / 2);
        }
    }

    &_noBlurOnDisable {
        .Select.is-disabled {
            > .Select-control {
                background: transparent;
                border-color: rgba(0, 0, 0, 0.2);
            }
        }
    }
}

.amber-modal {
    z-index: 10000;
    position: fixed;

    & h2 {
        margin: 0;
    }
}

.amber-modal__window-inner {
    max-width: 100%;
    min-width: calc(env(--unit) * 36);
}

.LangSwitcher {
    padding: 0 calc(env(--unit) * 2);
    position: relative;
    text-transform: uppercase;

    & .LangSwitcher__dropdown {
        color: black;
        background: white;
        position: absolute;
        left: 0;
        top: calc(100% + env(--unit) / 2);
        box-shadow: 0 8px 32px 0 #dcdee0;
        z-index: 2;
    }

    & .LangSwitcher__selected-language {
        cursor: pointer;
    }

    & .LangSwitcher__language {
        padding: env(--unit) calc(env(--unit) * 2);
        border-bottom: 1px solid env(--WarmGray100Color);
        cursor: pointer;
    }

    & .LangSwitcher__language:hover {
        background: env(--WarmGray50Color);
    }
}

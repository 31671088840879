.Checkboxes {
    padding: $unit 0;

    .amber-checkbox {
        display: block;
    }

    .amber-checkbox + .amber-checkbox {
        margin-top: $unit;
    }
}

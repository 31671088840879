.Select-placeholder.OrderFormRequirements {
    display: flex;
}

.OrderFormRequirements {
    .Select {
        &-menu-outer {
            width: 380px;

            .Select-value {
                margin: 0;
            }

            .Select-value-label {
                line-height: 38px;
            }
        }

        &-option {
            padding-left: 0;
            padding-right: 0;

            &_select {
                overflow: visible;

                &.is-selected, &.is-focused {
                    background-position: 10px 7px;
                }

                .Select-menu-outer {
                    width: 320px;
                }
            }

            &_multiselect {
                &.is-selected, &.is-focused {
                    background: transparent !important;
                }
            }
        }
    }
}

.Quantity {
    display: flex;
    line-height: 1.4;

    &__action {
        width: 18px;
        height: 18px;
        line-height: 14px;
        font-size: 14px;
        padding: 3px 0;
        box-sizing: border-box;
        border-radius: 12px;
        background: #eee;
        text-align: center;

        &_active {
            background: #ffdb4d;
            cursor: pointer;

            &:hover {
                background: #ffd633;
            }
        }

        &_dec {
            margin-right: 0.25em;
        }

        &_inc {
            margin-left: 0.25em;
        }
    }

    &__value {
        flex-grow: 1;
        text-align: center;
    }
}

.wrapper {
    cursor: text;
}

.wrapper input,
.wrapper textarea {
    padding: 0;
    border: none;
    background: transparent;
}

.icon {
    color: rgba(196, 194, 190, 1);
    cursor: pointer;
    visibility: hidden;
}

.wrapper:hover .icon {
    visibility: visible;
}

[dir='rtl'] .phoneInput {
    text-align: initial;
}

[dir='rtl'] .phoneInputWithMask {
    text-align: right;
}

.timeRangeInput {
    height: 45px;
}

.inputMask {
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    color: inherit;
    width: 100%;
}

.timeInput {
    text-align: center;
}

.timeInput input {
    cursor: default;
}

.listItem::before {
    border: none !important;
}

/*! rtl:begin:ignore */
.label.ltr {
    transform-origin: top left;
}

/*! rtl:end:ignore */

.root {
    padding: 0 var(--spacingUnits-m) var(--spacingUnits-m);
    border-radius: var(--spacingUnits-l);
    background-color: var(--colorScheme-background);
    height: fit-content;
    overflow-x: hidden;
}

.noTitle.noTitle {
    padding-block-start: var(--spacingUnits-m);
}

.noXPadding.noXPadding {
    padding-inline-start: unset;
    padding-inline-end: unset;
}

.xsPB.xsPB {
    padding-block-end: var(--spacingUnits-xs);
}

.minor {
    background-color: var(--colorScheme-bgMinor);
}

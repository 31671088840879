.root {
    position: relative;

    --arrowsVerticalOffset: 0;
    --arrowsHorizontalOffset: 0;
    --overflowXOffset: 0;
    --overflowYOffset: 0;
    --spacing: 0;
}

.carouselRoot {
    margin-inline-start: calc(0px - var(--overflowXOffset));
    margin-inline-end: calc(0px - var(--overflowXOffset));
    margin-block-start: calc(0px - var(--overflowYOffset));
    margin-block-end: calc(0px - var(--overflowYOffset));
}

.carousel {
    display: flex;
    box-sizing: content-box !important;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-inline-start: var(--overflowXOffset);
    padding-inline-end: var(--overflowXOffset);
    padding-block-start: var(--overflowYOffset);
    padding-block-end: var(--overflowYOffset);
}

.carousel::-webkit-scrollbar {
    display: none;
}

.slider {
    position: relative;
    width: 100%;
    box-sizing: content-box !important;

    --item-width: 0;
}

.item,
.item > * {
    width: var(--item-width);
}

.carousel :not(:last-child).item {
    margin-inline-end: var(--spacing);
}

.arrow {
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(calc(-50% + var(--arrowsVerticalOffset)));

    --arrowOffset: calc(var(--arrowsHorizontalOffset) + var(--overflowXOffset));
}

.left {
    left: var(--arrowOffset);
}

.right {
    right: var(--arrowOffset);
}

.root {
    height: var(--content-height);
    background: var(--colorScheme-background);
}

.root.s {
    flex-direction: column;
}

.image {
    width: 200px;
    height: 200px;
}

.image:not(.s) {
    margin-inline-end: var(--spacingUnits-l);
}

.details {
    width: 100%;
    max-width: 475px;
}

.button:not(.s) {
    max-width: 50%;
}

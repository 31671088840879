.divider {
    height: 1px;
    background-color: var(--colorScheme-line);
}

.divider.dividerLight {
    background-color: var(--colors-warmGray-350);
    height: 0.5px;
    opacity: 0.4;
}

.dialog {
    padding: 0;
    overflow: visible;
    background: none;
    box-shadow: none;
    filter: drop-shadow(0 8px 20px rgba(0, 0, 0, 0.12));
}

.image {
    position: absolute;
    display: flex;
    left: 50%;
    transform: translateX(-50%);
}

.top {
    width: 100%;
    height: 52px;
    margin-block-end: calc(-8px - var(--spacingUnits-m));
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTI4IiBoZWlnaHQ9IjUyIiB2aWV3Qm94PSIwIDAgNTI4IDUyIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPgo8cGF0aCBkPSJNNTI4IDUxLjVIMFYyOC40Mzk4QzAgMTEuMzQ2OCAxNS4yMzExIC0xLjczNTk0IDMyLjE2NTEgMC41OTAwNDFDMjA3Ljc5IDI0LjcxMyAzMTkuMjkzIDIzLjY0NTQgNDk2LjA0NSAwLjM0OTY0OEM1MTIuOTA4IC0xLjg3MjkxIDUyOCAxMS4xODc2IDUyOCAyOC4xOTY2VjUxLjVaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.contentContainer {
    z-index: 1;
    border-radius: inherit;
}

.contentContainer + .contentContainer {
    margin-block-start: 12px;
}

.contentContainer.s + .contentContainer.s {
    margin-block-start: var(--spacingUnits-xs);
}

.content {
    padding: var(--spacingUnits-m);
    background-color: var(--colorScheme-background);
}

.contentContainer .content {
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
}

.contentContainer + .contentContainer .content {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
}

.contentContainer:last-child .content {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

.contentContainer + .contentContainer .top {
    display: none;
}

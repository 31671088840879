.tag {
    height: unset !important;
    padding-block-start: var(--spacingUnits-xs);
    padding-block-end: var(--spacingUnits-xs);
}

.s {
    min-height: 28px;
}

.m {
    min-height: 32px;
}

.error.transparent {
    background-color: rgba(255, 73, 48, 0.15);
    color: var(--colorScheme-error) !important;
}

.warning.transparent {
    background-color: rgba(255, 186, 143, 0.5);
    color: var(--colorScheme-text) !important;
}

.minor {
    color: var(--colorScheme-textMinor) !important;
}

.noIconMargin svg {
    margin: 0;
}

.noSpanMargin span {
    margin: 0;
}

.Content {
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: var(--app-height);
    padding-block-start: var(--header-height);
    padding-inline-start: var(--menu-width);
}

.bgMinor {
    background-color: var(--colorScheme-bgMinor);
}

.bgDark {
    background-color: var(--colorScheme-dividerMinor);
}

.Content_ie {
    /* Специально для IE11 */
    height: 0;
}

.tag {
    font-weight: var(--fonts-weights-medium);
}

.tag.good {
    background-color: rgba(28, 192, 82, 0.15);
    color: var(--colors-green-dark) !important;
}

.tag.normal {
    background-color: rgba(90, 195, 26, 0.15);
    color: var(--colors-lime-normal) !important;
}

.tag.bad {
    background-color: var(--colorScheme-bgMinor);
    color: var(--colorScheme-textMinor) !important;
}

.TimeInput {
    text-align: center;
    width: ($unit * 7);

    &_iconCount_1 {
        width: ($unit * 7) + 32;
    }

    &_iconCount_2 {
        width: ($unit * 7) + 64;
    }

    .amber-input__control {
        text-align: center;
    }
}

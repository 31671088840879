.left,
.right,
.bottom {
    bottom: var(--sidePanel-bottom) !important;
}

.left,
.right {
    max-height: calc(100% - var(--sidePanel-bottom)) !important;
}

.top,
.bottom {
    max-height: calc(100% - var(--spacingUnits-xl) - var(--sidePanel-bottom)) !important;
}

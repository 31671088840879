.container {
    width: 40px;
    height: 40px;
    position: relative;
}

.icon {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.two {
    scale: 0.6;
}

.three {
    scale: 0.5;
}

.two.first {
    rotate: 8deg;
    translate: -7px -6px;
}

.two.second {
    translate: 7px 7px;
}

.three.first {
    translate: -9px -9px;
    rotate: 4deg;
}

.three.second {
    rotate: -4deg;
    translate: 7px -3px;
}

.three.third {
    rotate: 17deg;
    translate: -3px 9px;
}

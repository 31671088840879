@import './shared.styl';

.amber-block {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

for $breakpoint, $width in $breakpoints {
    if ($width == 0) {
        .amber-block {
            make-paddings($breakpoint, $width);

            for $grow in 0 .. 10 {
                +variant('grow-' + $breakpoint + '_' + $grow) {
                    flex-grow: $grow;
                    flex-basis: 0;
                }
            }

            make-shrink($breakpoint);
            make-flex($breakpoint);
        }
    } else {
        @media (min-width: $width) {
            .amber-block {
                make-paddings($breakpoint, $width);
                make-grow($breakpoint);
                make-shrink($breakpoint);
                make-flex($breakpoint);
            }
        }
    }
}

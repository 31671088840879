.wrapper {
    width: 100%;
}

.wrapper input,
.wrapper textarea {
    padding: 0;
    border: none;
    background: transparent;
}

.create {
    color: var(--colors-shamelessBlue-normal);
}

.ellipsis {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.listItem::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    border-top: 1px solid rgba(0, 0, 0, 0.08) !important;
}

.multiValue {
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    flex-shrink: 0;
}

.multiValueWrap {
    width: 100%;
}

.input {
    flex: 1;
}

.input input:placeholder-shown {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.mobileBody {
    margin-block-start: 10px;
    background-color: var(--colorScheme-background);
}

.mobileListWrapper {
    overflow: auto;
    transform: translateZ(0);
    border-radius: unset;
    margin-block-start: unset !important;
}

.mobileListInnerWrapper {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
}

.select.s [class*='trail_']:has([class*='clear_']) {
    --trailing-offset: 0;
}

.select.s [class*='trail_'][class*='clear_'] {
    --trailing-offset: 0;
}

.select.s [class*='trail_']:has([class*='toggle_']) {
    display: none;
}

.select.s [class*='trail_'][class*='toggle_'] {
    display: none;
}

.panel {
    height: calc(100% - var(--spacingUnits-xl) - var(--sidePanel-bottom)) !important;
}

.closeButton {
    position: absolute;
    inset-block-start: var(--spacingUnits-m);
    inset-inline-end: var(--spacingUnits-m);
}

.hidePopper {
    display: none;
    position: fixed;
    inset-block-start: 200%;
    inset-inline-end: 200%;
    width: 0;
    height: 0;
    overflow: hidden;
}

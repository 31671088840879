.ErrorWrapper {
    &__errors {
        color: $RedNormal;
        padding-top: 3px;

        a {
            color: inherit;
            text-decoration: underline;
        }
    }
}

.root {
    position: relative;
    transition: background-color 0.1s linear;
}

.root::before,
.root::after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    pointer-events: none;
    box-sizing: border-box;
}

.root.trailingPadding::before {
    inset-inline-start: calc(1px * var(--border-offset));
    inset-inline-end: calc(1px * var(--border-offset));
}

.root.focused::after {
    border: var(--components-listItems-borderFocused);
}

.root:not(.interactive:where(.focused, :hover, .pressed)) + .root.divided:not(.focused)::before {
    border-top: 1px solid var(--components-listItems-divider);
}

.root.divided.interactive:is(.focused, :hover, .pressed)::before,
.root.divided:not(.interactive).focused + .root.divided:not(.focused)::before {
    border-top: unset !important;
}

.root.interactive:hover {
    background-color: var(--components-listItems-bgColorHovered);
}

.root.interactive.pressed {
    background-color: var(--components-listItems-bgColorPressed);
}

.pointer {
    cursor: pointer;
}

.forceDivider::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: inherit;
    pointer-events: none;
    box-sizing: border-box;
    border-top: 1px solid var(--components-listItems-divider);
}

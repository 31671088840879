.shortcut {
    --shortcut-background: rgba(92, 90, 87, 0.1);
    --shortcut-simpleBackground: var(--shortcut-background);

    position: relative;
    height: 104px;
    border-radius: 16px;
    overflow: hidden;
    text-align: start;
    color: inherit;
    background: var(--shortcut-background);
}

.shortcut.large {
    height: 160px;
    border-radius: 20px;
}

.shortcut.clickable {
    cursor: pointer;
}

:global(.utilityfocus) .shortcut:focus::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: inherit;
    pointer-events: none;
    border: var(--components-buttons-default-borderFocused);
}

.shortcut.taxi {
    --shortcut-background: #f7f2e1;
}

.shortcut.eats,
.shortcut.lavka {
    --shortcut-background: #f5f4f2;
}

.shortcut.delivery {
    --shortcut-background: #f5eae1;
}

.shortcut.attention {
    --shortcut-background: #f5e1f7;
}

.shortcut.ultima {
    --shortcut-background: #e8ebed;
}

.shortcut.transport {
    --shortcut-background: #dce9f5;
}

.shortcut.discount1 {
    --shortcut-background: linear-gradient(
        153.43deg,
        rgba(181, 232, 104, 0.6) 0%,
        rgba(119, 232, 247, 0.6) 83.33%
    );
    --shortcut-simpleBackground: #b7f1e3;
}

.shortcut.discount2 {
    --shortcut-background: linear-gradient(
        153.43deg,
        rgba(255, 188, 163, 0.6) 0%,
        rgba(242, 189, 255, 0.6) 83.33%
    );
    --shortcut-simpleBackground: #ebd2eb;
}

.shortcut.discount3 {
    --shortcut-background: linear-gradient(
        153.43deg,
        rgba(255, 189, 204, 0.6) 0%,
        rgba(255, 191, 122, 0.6) 83.33%
    );
    --shortcut-simpleBackground: #f5d6c1;
}

.shortcut.action {
    --shortcut-background: var(--colors-yellow-high);
}

.shortcut.focused {
    --shortcut-background: var(--colors-absolute-white);

    border: var(--components-buttons-default-borderFocused);
}

.shortcut.disabled {
    --shortcut-background: var(--colors-warmGray-100);

    pointer-events: none;
    color: var(--colorScheme-textMinor);
}

@keyframes loadingEffect {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}

.shortcut.loading::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    background: var(--components-buttons-loadingGradient);
    animation: loadingEffect 2s infinite;
}

.shortcut.invert::before {
    content: '';
    position: absolute;
    top: 2px;
    bottom: 2px;
    left: 2px;
    right: 2px;
    border-radius: inherit;
    background-color: var(--colorScheme-background);
}

.header,
.footer {
    z-index: 0;
}

.header {
    padding: 13px;
    overflow: hidden;
}

.closable .header {
    padding-right: 40px;
}

.footer {
    position: relative;
    margin-top: auto;
}

.sticker {
    position: absolute;
    bottom: 10px;
    left: 10px;
    transform-origin: bottom left;
    transform: rotate(-3deg);
}

.shortcut.invert .sticker {
    filter: none;
    background: var(--shortcut-simpleBackground);
}

.closeButton {
    position: absolute;
    top: 8px;
    right: 8px;
}

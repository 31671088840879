.title {
    min-height: 68px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.content {
    margin-right: calc(0px - var(--spacingUnits-l));
    margin-left: calc(0px - var(--spacingUnits-l));
    transition: padding 0.3s;
    padding: 0 var(--spacingUnits-l);
}

.content.shadow {
    box-shadow: inset 0 0 20px rgba(117, 115, 111, 0.25);
}

.content.open {
    padding: 16px var(--spacingUnits-l);
}

.chevron.open {
    transform: scaleY(-1);
    transition: transform 0.3s;
}

.root {
    color: var(--colorScheme-bgEducation);
}

.root.yango {
    color: var(--colorScheme-text);
}

:global(.utilityfocus) .root:focus {
    outline: var(--components-buttons-default-borderFocused);
}

.divider {
    width: 1px;
    height: 28px;
    background-color: currentColor;
    opacity: 0.2;
}

.clientName {
    white-space: nowrap;
}

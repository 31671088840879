.base {
    width: 0;
    height: 0;
}

/*! rtl:begin:ignore */

.pinWrapper {
    max-width: 360px;
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -100%);
    cursor: pointer;
}

/*! rtl:end:ignore */

.pinContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pinBody {
    display: flex;
    align-items: center;
    padding: 4px;
    border-radius: 16px;
    background-color: var(--colorScheme-background);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.15), 0 8px 20px rgba(0, 0, 0, 0.12);
    transition: transform 0.3s;
}

.pinBody.cargoStart {
    background-color: var(--colors-absolute-black);
}

.pinBody.cargoMid,
.pinBody.cargoFinish {
    background-color: var(--colors-warmGray-550);
}

.pinBody.pickupPoint.invert {
    background-color: var(--colors-absolute-black);
}

.shadow {
    width: 12px;
    height: 6px;
    margin-block-start: -3.5px;
    margin-block-end: -3px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.2);
    filter: blur(1px);
}

.pinStick {
    width: 3px;
    height: 24px;
    background: var(--colors-warmGray-600);
    border-radius: 0 0 8px 8px;
}

.pinIconContainer {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    display: flex;
    place-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    background-color: var(--colorScheme-control);
    background-size: contain;
}

.pinIconContainer.invert {
    background-color: var(--colors-absolute-black);
}

.pinIconContainer.pickupPoint {
    background-color: var(--colors-absolute-black);
}

.pinIconContainer.pickupPoint.invert {
    background-color: var(--colors-warmGray-500);
}

.pinIconContainer.pickupPoint.gray {
    background-color: var(--colors-warmGray-500);
}

.pinIconContainer.cargoStart {
    background-color: var(--colors-absolute-black);
}

.pinIconContainer.cargoMid,
.pinIconContainer.cargoFinish {
    background-color: #dddcda;
}

.pinIcon {
    width: 24px;
    height: 24px;
    margin: auto;
    background: url('./assets/pin-human.svg') no-repeat center;
}

.pinIcon.loading {
    background-image: url('./assets/pin-spinner.svg');
    animation: spinner 1s linear infinite;
}

.pinIcon.finish {
    background-image: url('./assets/pin-finish.svg');
}

.pinWaitingTime {
    font-family: YS Text, serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: -0.005em;
}

.pinWaitingTimeCaption {
    font-family: YS Text, serif;
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.01em;
}

.pinIcon.finish.invert {
    background-image: unset;
    background-color: var(--colors-absolute-white);
    mask-image: url('./assets/pin-finish.svg');
    -webkit-mask-image: url('./assets/pin-finish.svg');
    mask-size: contain;
    -webkit-mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
}

.pinIcon.pickupPoint {
    background-image: url('./assets/pin-pickup-point.svg');
}

.pinIcon.terminal {
    background-image: url('./assets/pin-terminal.svg');
}

.pinIcon.cargoStart {
    background-image: url('./assets/pin-cargo-start.svg');
}

.pinIcon.cargoFinish {
    background-image: url('./assets/pin-finish.svg');
}

.pinDescription {
    margin-inline-start: 6px;
    margin-inline-end: 4px;
    transition: all 0.3s;
}

.pinDescription.pickupPoint.invert {
    color: var(--colors-absolute-white);
}

.pinDescription.empty {
    width: 0;
    margin: 0;
}

.empty {
    transition: all 0.3s;
}

.pinTitle {
    font-family: YS Text, serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0;
    text-align: left;
    transition: none;
}

.pinSubTitle {
    font-family: YS Text, serif;
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.01em;
    text-align: left;
    transition: none;
}

.pinTitle.empty,
.pinSubTitle.empty {
    display: none;
}

.dNone {
    display: none;
}

.circle {
    cursor: pointer;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    color: #fff;
    font-size: 11px;
    line-height: 14px;
    font-weight: 700;
    text-align: center;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.15), 0 8px 20px rgba(0, 0, 0, 0.12);
}

.circle.midPoint {
    width: 32px;
    height: 32px;
    border: 1px solid var(--colors-absolute-white);
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}

.circle.pickup {
    box-shadow: unset;
    position: relative;
    background-color: var(--colors-warmGray-550);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;
}

.circle.pickup .center {
    border-radius: 50%;
    display: block;
    width: 8px;
    height: 8px;
    background-color: var(--colors-absolute-white);
}

.cluster {
    background-color: var(--colors-absolute-black);
    width: 36px;
    height: 36px;
    border: 1px solid var(--colors-absolute-white);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}

.center.windows {
    position: absolute;
    inset-block-start: 50%;
    inset-inline-start: 50%;
    transform: translate(-50%, -46%);
}

.pickup.isAttracting {
    width: 18px;
    height: 18px;
}

.pickup.isAttracting::after {
    width: 10px;
    height: 10px;
}

.arrow {
    visibility: hidden;
    width: 24px;
    height: 8px;
    position: relative;
    background-color: var(--colors-absolute-white);
}

.arrow::before {
    --mask-image: url("data:image/svg+xml,%3Csvg width='24' height='8' viewBox='0 0 24 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m12 8-.422-.845a26.859 26.859 0 0 0-.505-.984A12 12 0 0 0 1.106.011C.845 0 .563 0 0 0h24c-.563 0-.845 0-1.106.011a12 12 0 0 0-9.967 6.16c-.127.229-.253.48-.505.984L12 8Z'/%3E%3C/svg%3E");

    content: '';
    visibility: visible;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    mask-image: var(--mask-image);
    -webkit-mask-image: var(--mask-image);
    box-sizing: border-box;
    background-color: inherit;
}

.pusher {
    width: 0;
    height: 0;
    transition: all 0.3s;
}

.pusher.dragging {
    height: 10px;
}

.pusher.isAttracting {
    height: 8px;
}

.tooltip {
    pointer-events: none;
}

.wayTime {
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.85);
    padding: 3px 6px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    width: fit-content;
}

.wayTimeText {
    white-space: nowrap;
}

@keyframes spinner {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.cargoDriverPoint {
    width: 64px;
    height: 33px;
    transform: translateX(-32px) translateY(-16.5px);
    background-image: url('./assets/pin-cargo-car.svg');
    background-size: contain;
}

.cargoBoxPoint {
    width: 88px;
    height: 51px;
    transform: translateX(-44px) translateY(-25.5px);
    background-image: url('./assets/pin-cargo-box.svg');
    background-size: contain;
}

.grayPinDot {
    border-color: var(--colors-warmGray-450) !important;
}

.Loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;

    &:after {
        content: '';
        width: 0;
        height: 100%;
        display: inline-block;
        vertical-align: middle;
    }

    &_static {
        position: static;
        margin-bottom: 16px;
        padding-top: 16px;
        background: transparent;

        &:after {
            display: none;
        }
    }

    &_block {
        position: static;
        background: transparent;

        &:after {
            display: none;
        }
    }

    &_center {
        position: static;
        flex-grow: 1;
        align-self: center;
        justify-self: center;
        display: flex;
        align-items: center;
        justify-items: center;
    }
}

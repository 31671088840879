.item {
    width: min-content;
    min-width: 70px;
}

.button svg {
    width: 32px;
    height: 32px;
}

.label {
    text-align: center;
}

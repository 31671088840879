@import './size.styl';

make-column-helpers($breakpoint) {
    .amber-col {
        +variant('verticalAlign-' + $breakpoint + '_top') {
            justify-content: flex-start;
            display: flex;
            flex-direction: column;
        }

        +variant('verticalAlign-' + $breakpoint + '_center') {
            justify-content: center;
            display: flex;
            flex-direction: column;
        }

        +variant('verticalAlign-' + $breakpoint + '_bottom') {
            justify-content: flex-end;
            display: flex;
            flex-direction: column;
        }

        +variant('horizontalAlign-' + $breakpoint + '_start') {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
        }

        +variant('horizontalAlign-' + $breakpoint + '_center') {
            align-items: center;
            display: flex;
            flex-direction: column;
        }

        +variant('horizontalAlign-' + $breakpoint + '_end') {
            align-items: flex-end;
            display: flex;
            flex-direction: column;
        }
    }
}

make-default-column($breakpoint) {
    .amber-col {
        +variant($breakpoint) {
            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: 0;
            max-width: 100%;
        }
    }
}

make-responsive-column($breakpoint, $column, $width) {
    .amber-col {
        +variant('columns-' + $breakpoint + '_' + $column) {
            flex: 0 0 $width;
            max-width: $width;
        }
    }
}

make-responsive-columns($columns, $breakpoint) {
    for $column in 0 .. $columns {
        $width = 0;

        if ($column != 0) {
            $width = percentage(1 / $columns * $column);
        }

        make-responsive-column($breakpoint, $column, $width);
    }
}

make-offset-column($breakpoint, $column, $margin) {
    .amber-col {
        +variant('offset-' + $breakpoint + '_' + $column) {
            margin-left: $margin;
        }
    }

    // В реверснутых Row всё работает наоборот
    .amber-row {
        +variant('reverse-' + $breakpoint) {
            .amber-col {
                +variant('offset-' + $breakpoint + '_' + $column) {
                    margin-left: 0;
                    margin-right: $margin;
                }
            }
        }
    }
}

make-offset-columns($columns, $breakpoint) {
    for $column in 0 .. $columns {
        $offset = 0;

        if ($column != 0) {
            $offset = percentage(1 / $columns * $column);
        }

        make-offset-column($breakpoint, $column, $offset);
    }
}

make-column($breakpoint, $columns) {
    make-column-helpers($breakpoint);
    make-default-column($breakpoint);
    make-responsive-columns($columns, $breakpoint);
    make-offset-columns($columns, $breakpoint);
}

make-flex($breakpoint) {
    +variant('flex-' + $breakpoint) {
        display: flex;
        flex-direction: column;
    }
}

make-columns($breakpoints, $columns) {
    for $breakpoint, $width in $breakpoints {
        if ($width == 0) {
            make-column($breakpoint, $columns);

            .amber-col {
                make-paddings($breakpoint, $width);

                for $grow in 0 .. 10 {
                    +variant('grow-' + $breakpoint + '_' + $grow) {
                        flex-grow: $grow;
                        flex-basis: 0;
                    }
                }

                make-shrink($breakpoint);
                make-flex($breakpoint);
            }
        } else {
            @media (min-width: $width) {
                make-column($breakpoint, $columns);

                .amber-col {
                    make-paddings($breakpoint, $width);
                    make-grow($breakpoint);
                    make-shrink($breakpoint);
                    make-flex($breakpoint);
                }
            }
        }
    }
}

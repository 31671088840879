.root {
    position: relative;
    height: 2px;
    flex-shrink: 0;
    color: var(--colorScheme-dividerMinor);
    background: currentColor;
    z-index: 10;
}

.root.s {
    color: var(--colors-absolute-black);
}

.radiusIcon {
    position: absolute;
    pointer-events: none;
}

/*! rtl:begin:ignore */

.radiusIcon.topLeft {
    bottom: 100%;
    left: 0;
    transform: rotate(-90deg);
}

.radiusIcon.topRight {
    bottom: 100%;
    right: 0;
    transform: rotate(180deg);
}

.radiusIcon.bottomLeft {
    top: 100%;
    left: 0;
}

.radiusIcon.bottomRight {
    top: 100%;
    right: 0;
    transform: rotate(90deg);
}

/*! rtl:end:ignore */

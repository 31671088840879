.title {
    height: calc(var(--components-slots-size-s) + var(--components-slots-size-xs));
    border-top-left-radius: var(--spacingUnits-l);
    border-top-right-radius: var(--spacingUnits-l);
}

.title.bottomRounded {
    border-bottom-left-radius: var(--spacingUnits-l);
    border-bottom-right-radius: var(--spacingUnits-l);
}

.island {
    display: flex;
    flex-grow: 1;
    margin-block-end: 0 !important;
}

.islandPaper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.icon {
    transition: transform 0.2s ease-out;
    transform: rotateZ(0);
}

.icon.expanded {
    transform: rotateZ(-90deg);
}

.skeleton.xxl {
    height: 40px;
    width: 40px;
}

.listItem {
    pointer-events: none;
}

.listItem.clickable {
    pointer-events: auto;
}

.details {
    border-block-start: var(--components-listItems-borderDivider);
}

.Label {
    display: inline-block;

    &_type_checkbox {
        padding-top: 6px;
        padding-right: $unit;
    }

    &_type_center {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &_type_input {
        padding-top: ($unit * 1.5);
        padding-right: $unit;
    }
}

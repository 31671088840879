.spinner {
    background: url('../icon/images/spinner.svg') no-repeat center;
    background-size: cover;
    margin: $unit auto;

    &_inline {
        display: inline-block;
    }

    &_size {
        &_xl {
            width: 48px;
            height: 48px;
        }

        &_l {
            width: 38px;
            height: 38px;
        }

        &_m {
            width: 32px;
            height: 32px;
        }

        &_s {
            width: 28px;
            height: 28px;
        }

        &_xs {
            width: 24px;
            height: 24px;
        }

        &_xxs {
            width: 16px;
            height: 16px;
        }
    }
}

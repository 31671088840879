.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.root {
    --conic-gradient: '';

    width: 152px;
    height: 152px;
    background: conic-gradient(var(--conic-gradient));
}

.inner {
    background: var(--colorScheme-background);
    width: 112px;
    height: 112px;
}

.root {
    --rows: 1;

    position: relative;
}

.content {
    display: block;
    overflow: hidden;
    position: relative;
}

.anchor {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
}

.content.breakAll {
    word-break: break-all;
}

@supports (display: -webkit-box) {
    .root .content {
        display: -webkit-box;
        -webkit-line-clamp: var(--rows);
        -webkit-box-orient: vertical;
    }
}

.badge {
    border-radius: 100px;
}

.badge.m {
    padding: 3px 6px;
}

.badge.s {
    padding: 2px 4px;
}

.badge.default {
    color: var(--colorScheme-text);
    background-color: var(--colorScheme-background);
    box-shadow: var(--shadows-bottomClose);
}

.badge.success {
    color: var(--colors-absolute-white);
    background-color: var(--colorScheme-success);
}

.badge.warning {
    color: var(--colors-absolute-white);
    background-color: var(--colors-orange-normal);
}

.badge.important {
    color: var(--colors-absolute-white);
    background-color: var(--colors-cinnabar-toxic);
    box-shadow: 0 2px 5px rgba(212, 65, 46, 0.3);
}

.badge.invert {
    color: var(--colorScheme-textInvert);
    background-color: var(--colorScheme-bgInvert);
}

.card {
    box-shadow: none;
}

.main {
    background-color: var(--colorScheme-background);
    transform: translateZ(0);
}

.minor {
    background-color: var(--colorScheme-bgMinor);
}

.skeleton {
    width: unset;
    border-radius: var(--border-radius, none);
}

@keyframes loadingEffect {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}

.root {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    border-radius: var(--border-radius);
    pointer-events: none;
}

.root::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    animation: loadingEffect 2s infinite;
    background: var(--components-buttons-loadingGradient);
}

.modal,
.panel {
    padding: var(--spacingUnits-m);
}

.modal {
    position: relative;
    max-width: calc(100vw - var(--spacingUnits-m) * 2);
    margin: var(--spacingUnits-m);
    text-align: left;
}

.panel {
    overflow-y: auto;
}

.title {
    word-break: break-word;
}

.closeButton {
    flex-shrink: 0;
    margin-left: var(--spacingUnits-m);
}

.body {
    flex: 1 1 auto;
}

.footer {
    margin-block-start: 40px;
    flex: 0 0 auto;
}

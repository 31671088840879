* {
  outline: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: 'Yandex Sans Text Web', Arial, sans-serif;
  font-size: 10px;
}
body {
  font-family: 'Yandex Sans Text Web', Arial, sans-serif;
  font-size: 13px;
  line-height: 1.2;
  font-weight: normal;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #21201f;
}
table {
  border-collapse: collapse;
  width: 100%;
}
h1,
h2 {
  font-family: 'Yandex Sans Text Web', Arial, sans-serif;
  font-size: 32px;
  font-weight: 400;
  word-spacing: 4px;
  padding: 0;
  margin: 0;
}
h3 {
  font-family: 'Yandex Sans Text Web', Arial, sans-serif;
  font-size: 32px;
  font-weight: 400;
  margin: 0;
  padding: 0;
}
.island {
  background: #fff;
  box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 3px 4px -3px rgba(0,0,0,0.3);
}
hr {
  box-sizing: content-box;
  height: 0;
  border: 0;
  padding: 0;
  border-top: 1px solid #eee;
  flex-grow: 1;
  margin-top: 0;
  margin-bottom: 0;
}
ul {
  margin: 0;
  padding: 0;
}
@-moz-keyframes fade-in {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
}
@-webkit-keyframes fade-out {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
}
@-moz-keyframes half-rotate {
  to {
    transform: rotate(180deg);
  }
}
@-webkit-keyframes half-rotate {
  to {
    transform: rotate(180deg);
  }
}
@-o-keyframes half-rotate {
  to {
    transform: rotate(180deg);
  }
}
@keyframes half-rotate {
  to {
    transform: rotate(180deg);
  }
}
.nowrap {
  min-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.page-404,
.page-403 {
  padding: 50px;
}
.gray {
  color: #949392;
}
.react-datepicker .react-datepicker__navigation {
  z-index: 1;
}
.react-datepicker__tether-element {
  z-index: 2147483647;
}

.root {
    white-space: normal;
}

.root p {
    margin: 0;
}

.root b {
    font-weight: var(--fonts-weights-bold);
}

.root ul,
.root ol {
    padding-inline-start: var(--spacingUnits-l) !important;
}

.root summary {
    cursor: pointer;
}

.root p:not(:last-child),
.root ul:not(:last-child),
.root details:not(:last-child),
.root details[open] summary {
    margin-block-end: var(--spacingUnits-s);
}

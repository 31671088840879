.tooltip {
    text-align: start;
}

.button {
    color: var(--colorScheme-textMinor);
    cursor: default;
}

.icon {
    width: 20px;
    height: 20px;
}

.fullScreenRoot {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #21201f;
    z-index: 2000;
    padding-block-start: 60px;
    padding-block-end: 60px;
}

.fullScreenRoot.s {
    padding-block-start: 72px;
    padding-block-end: 98px;
}

.fullScreenTitle {
    position: absolute;
    right: 16px;
    left: 16px;
    top: 16px;
    min-height: 24px;
    height: fit-content;
}

.cross {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
}

.fullScreenImage {
    border-radius: 24px;
    object-fit: contain;
    max-width: 1264px;
    max-height: 100%;
}

.fullScreenImage.s {
    max-width: 100%;
    border-radius: 16px;
}

.image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 16px;
    overflow: hidden;
}

.white {
    color: var(--colors-absolute-white);
}

.swingButton {
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
}

.swingButton.s {
    transform: unset;
    bottom: 16px;
}

.prev {
    left: 16px;
}

.next {
    right: 16px;
}

.showedImage {
    width: 100%;
    min-height: 178px;
}

.miniImage {
    min-height: 59px;
}

.miniImageButton {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.moreButton {
    background: rgba(0, 0, 0, 0.4);
}

.nav {
    margin-inline-start: -5px;
}

.gridRoot {
    --size: 0;

    flex-wrap: wrap;
    flex: 1;
}

.gridItem {
    width: calc(1px * var(--size));
    height: calc(1px * var(--size));
}

.gridItem.big {
    width: calc(2px * var(--size) + 4px);
    height: calc(2px * var(--size) + 4px);
}

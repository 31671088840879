.icon {
    display: inline-block;
    pointer-events: none;
    background-repeat: no-repeat;
    background-position: 50%;
    cursor: pointer;
    transition: transform 0.1s ease-out, opacity 0.2s ease-out;

    &_type_arrow-down {
        width: 28px;
        background-image: url('./images/arrow-down.svg');
    }

    &_type_close {
        width: 18px;
        height: 18px;
        background-image: url('data:image/svg+xml;charset=US-ASCII,<svg%20xmlns%3D"http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg"%20width%3D"10"%20height%3D"10"><polygon%20points%3D"10%2C0.714%209.287%2C0%205%2C4.286%200.714%2C0%200%2C0.714%204.286%2C5%200%2C9.285%200.714%2C10%205%2C5.714%209.287%2C10%2010%2C9.285%205.714%2C5"%2F><%2Fsvg>');
        background-size: 10px 10px;
    }

    &_type_remove {
        width: 31px;
        background-image: url('./images/trash.svg');
        opacity: 0.5;
    }

    &_type_add {
        width: 31px;
        background-image: url('./images/plus.svg');
        background-size: 11px auto;
        opacity: 0.5;
    }

    &_type_edit {
        width: 31px;
        background-image: url('./images/pencil.svg');
        opacity: 0.5;
    }

    &_type_star {
        width: 16px;
        height: 16px;
        background-image: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQ5MC4wMTYgNDkwLjAxNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDkwLjAxNiA0OTAuMDE2OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPGc+CgkJPHBhdGggZD0iTTI0NS4wMDYsMTEuOTkybC03MC45NDEsMTU5Ljk3TDAsMTkwbDEzMC4yMTcsMTE2LjkwNUw5My41ODQsNDc4LjAyM2wxNTEuNDIzLTg3LjcxN2wxNTEuNDIzLDg3LjcxN2wtMzYuNjMxLTE3MS4xMTggICAgTDQ5MC4wMTYsMTkwbC0xNzQuMDYzLTE4LjAzOEwyNDUuMDA2LDExLjk5MkwyNDUuMDA2LDExLjk5MnoiIGZpbGw9IiNGRkRBNDQiLz4KCTwvZz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K');
    }

    &_type_dropdown {
        width: 31px;
        background-image: url('./images/arrow-down.svg');
    }

    &_type_car {
        width: 31px;
        background-image: url('./images/car.svg');
    }

    &_type_unactive {
        width: 16px;
        height: 16px;
        background-image: url('./images/unactive.svg');
    }

    &_type_left {
        width: 31px;
        background-image: url('./images/left.svg');
    }

    &_type_right {
        width: 31px;
        background-image: url('./images/right.svg');
    }

    &_type_geolocation {
        width: 16px;
        margin-right: 4px;
        background-image: url('./images/geolocation.svg');
        background-size: 16px auto;
        opacity: 0.5;
    }

    &_type_surge {
        width: 12px;
        background-image: url('./images/surge.svg');
        background-size: auto 14px;
    }

    &_type_help {
        width: 24px;
        height: 24px;
        background-image: url('./images/help.svg');
        background-size: 24px 24px;
    }

    &_type_fill-arrow-right {
        width: 48px;
        height: 48px;
        background-image: url('./images/fill-arrow-right.png');
    }

    &_type_fill-arrow-left {
        width: 48px;
        height: 48px;
        background-image: url('./images/fill-arrow-right.png');
        transform: scaleX(-1);
    }
}

.amber-icon {
    display: inline-block;

    svg {
        display: block;
    }
}

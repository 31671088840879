.sections {
    background-color: var(--colorScheme-bgMinor);
}

.sections.bgMain {
    background-color: var(--colorScheme-background);
}

.sections.compact {
    background-color: var(--colorScheme-dividerMinor);
}

.item {
    margin-bottom: var(--spacingUnits-s);
    box-shadow: none;
}

.compact .item {
    margin-bottom: 2px;
}

.item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.item:last-child {
    margin-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.item.minor {
    background-color: var(--colorScheme-bgMinor);
}

.item.minor:first-child {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.item.minor:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

.item.shortDivider.l::before,
.item.shortDivider.m::before {
    margin: 0 16px;
}

.item.shortDivider.s::before {
    margin: 0 16px;
}

.item.shortDivider.xs::before {
    margin: 0 10px;
}

.item.invertDivider::before {
    --components-listItems-divider: var(--colorScheme-background);
}

.item.noDivider::before {
    --components-listItems-divider: transparent;
}

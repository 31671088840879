.RequirementItem {
    padding-top: $unit * 2;
    padding-bottom: $unit * 2;
    padding-left: $unit * 7;

    &_checked {
        background-image: url('data:image/svg+xml;charset=UTF-8,%20%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20width=%2724%27%20height=%2724%27%20viewBox=%270%200%2024%2024%27%3e%3cpolygon%20fill=%27%2321201F%27%20points=%279.274%2015.169%2018.327%206.116%2020.094%207.884%209.274%2018.705%203.116%2012.547%204.884%2010.779%27/%3e%3c/svg%3e');
        background-repeat: no-repeat;
        background-position: ($unit * 2) $unit;
    }
}
